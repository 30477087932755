import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import { TRIAL_PACKET_PAGEIDENTIFIER } from "src/constant";

const links = [
  { text: "packs", route: "/packets" },
  { text: "blog", route: "https://dongu.beije.co/", external: true },
  { text: "trial", route: `/product/${TRIAL_PACKET_PAGEIDENTIFIER}` },
  { text: "frequently-asked-questions", route: "/know-us/faqs" },
  // { text: "quiz", route: "/quiz" },
  {
    text: "join-our-team",
    route: "https://www.linkedin.com/company/beije-tr/",
    external: true,
  },
  { text: "who-we-are", route: "/know-us/why-beije" },
];

const Links = () => {
  const { t } = useTranslation();

  const containerStyle = (theme: Theme) => ({
    display: "grid",
    gridTemplateRows: "repeat(4, 2rem)",
    gridTemplateColumns: "repeat(2, 1fr)",
    gap: "1rem",
    [theme.breakpoints.down("md")]: {
      alignContent: "center",
      textAlign: "center",
      my: 3,
      mx: "auto",
    },
  });

  return (
    <Box component="nav" sx={containerStyle}>
      {links.map((link) => (
        <Box key={link.text} style={{ cursor: "pointer" }}>
          <Link href={link.route} target={link.external ? "_blank" : "_self"}>
            <Typography noWrap>{t(`footer:${link.text}`)}</Typography>
          </Link>
        </Box>
      ))}
    </Box>
  );
};

export default Links;
