export type MenuType =
  | "products"
  | "know-us"
  | "donation-culture"
  | "mobile"
  | "closed";

export const MENU_DATA: {
  [k: string]: {
    titleKey: string;
    route?: string;
    isExternal?: boolean;
    children?: {
      titleKey: string;
      route: string;
      frontendImage: string;
    }[];
  };
} = {
  products: {
    titleKey: "products",
  },
  "know-us": {
    titleKey: "know-us",
    children: [
      {
        titleKey: "why-beije",
        route: "/know-us/why-beije",
        frontendImage: "/images/nav/why.png",
      },
      {
        titleKey: "how-it-works",
        route: "/know-us/how-it-works",
        frontendImage: "/images/nav/how.png",
      },
      {
        titleKey: "price-policy",
        route: "/know-us/price-policy",
        frontendImage: "/images/nav/price.png",
      },
      {
        titleKey: "faq",
        route: "/know-us/faqs",
        frontendImage: "/images/nav/faq.png",
      },
      {
        titleKey: "all-materials",
        route: "/know-us/all-materials",
        frontendImage: "/images/nav/all-materials.png",
      },
    ],
  },
  "donation-culture": {
    titleKey: "donation-culture",
    children: [
      {
        titleKey: "our-philosophy",
        route: "/donation-culture",
        frontendImage: "/images/nav/our-philosophy.webp",
      },
      {
        titleKey: "pay-it-forward",
        route: "/product/donation",
        frontendImage: "/images/nav/donation-pad.webp",
      },
    ],
  },
  blog: {
    titleKey: "blog",
    route: "https://dongu.beije.co",
    isExternal: true,
  },
  // quiz: {
  //   title: "quiz",
  //   route: "/quiz",
  // },
  "custom-packet": {
    titleKey: "custom-packet",
    route: "/custom-packet",
  },
};
