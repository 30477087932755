import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "react-i18next";

export const TopHeader = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const topMenuItems = [
    {
      id: 1,
      title: t("nav:subscription-system"),
      route: "/know-us/how-it-works",
    },
    { id: 2, title: t("nav:easy-return"), route: "/know-us/faqs/orders" },
    { id: 3, title: t("nav:free-shipping"), route: "/know-us/faqs/orders" },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        background: theme.palette.primary.dark,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: { xs: 2, md: 8 },
      }}
    >
      {topMenuItems.map((item) => (
        <Link href={item.route} key={item.id}>
          <Typography
            component="p"
            variant="desktopSubtitle2"
            color="#ffffff"
            py={2}
          >
            {item.title}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};
