import { ShoppingCart01 } from "@beije/react-icons";
import { Badge, Icon } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import type { RootState } from "src/store";

type CartBadgeButtonProps = {
  color?: string;
};

export const CartBadgeButton = ({ color }: CartBadgeButtonProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const countOfCartItems = useSelector((state: RootState) =>
    state.CartSlice.items.reduce((acc, item) => acc + item.quantity, 0)
  );

  return (
    <Link href="/cart" aria-label={t("nav:aria.cart")}>
      <Badge
        color="success"
        badgeContent={countOfCartItems}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiBadge-badge": {
            padding: 0.7,
            paddingTop: 1.1,
            display: "flex",
            alignItems: "center",
            alignSelf: "center",
            justifyContent: "center",
          },
        }}
      >
        <Icon
          sx={{
            padding: 0.5,
            width: "auto",
            height: "auto",
            display: "inline-flex",
            position: "relative",
          }}
        >
          <ShoppingCart01 width={24} height={24} color={color} />
        </Icon>
      </Badge>
    </Link>
  );
};
