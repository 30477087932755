import { BeijeLogo17 } from "@beije/react-icons";
import { Box, Divider, Typography, useMediaQuery } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import desktopWavePattern from "public/images/desktop-wave-pattern.svg";
import mobileWavePattern from "public/images/mobile-wave-pattern.svg";

import { BottomNav } from "./BottomNav";
import Links from "./Links";
import NewsLetter from "./NewsLetter";
import SocialMedia from "./SocialMedia";

const WAVE_SVG_HEIGHT = 33;
const WavePattern = styled(Box, {
  shouldForwardProp: (props) => props !== "isMobile",
})<{ isMobile: boolean }>(({ isMobile }) => ({
  backgroundImage: isMobile
    ? `url(${mobileWavePattern.src})`
    : `url(${desktopWavePattern.src})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100% 100%",
  height: WAVE_SVG_HEIGHT,
  width: "100%",
  position: "absolute",
  top: -(WAVE_SVG_HEIGHT - 1),
}));

export const Footer = () => {
  const { t } = useTranslation();

  const myTheme = useTheme();
  const MDBreakpoint = useMediaQuery(myTheme.breakpoints.up("md"));
  const isMdDown = useMediaQuery(myTheme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        overflow: "hidden",
      }}
    >
      <WavePattern isMobile={isMdDown} />
      <Box
        component="footer"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          paddingY: { lg: 10, md: 10, sm: 5, xs: 5 },
          paddingX: { lg: 18, md: 18, sm: 3.375, xs: 3.375 },
          gap: { lg: 8, md: 8, sm: 3, xs: 3 },
          width: "100%",
          bgcolor: (theme) => theme.palette.primary.dark,
          color: (theme) => theme.palette.secondary.light,
        }}
      >
        <Box
          component="section"
          sx={{
            display: "flex",
            flexDirection: MDBreakpoint ? "row" : "column",
            alignItems: "flex-start",
            gap: { lg: 4, md: 4, sm: 5, xs: 5 },
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: { lg: "50%", md: "50%", sm: "100%", xs: "100%" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 3,
                width: { lg: "85%", md: "85%", sm: "100%", xs: "100%" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: {
                    lg: "flex-start",
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                  width: "100%",
                }}
              >
                <Link href="/" aria-label={t("common:aria.home")}>
                  <BeijeLogo17
                    width={MDBreakpoint ? 56.39 : 47}
                    height={MDBreakpoint ? 24 : 20}
                  />
                </Link>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "flex-start",
                  },
                  width: "100%",
                }}
              >
                <Typography
                  variant="desktopSubtitle1"
                  component="p"
                  color="#FFFFFFB2"
                  textAlign={{
                    sm: "center",
                    xs: "center",
                  }}
                >
                  {t("footer:news-letter.header")}
                </Typography>

                <Typography
                  component="p"
                  variant="desktopBody2"
                  color="#FFFFFFB2"
                  textAlign={{
                    md: "left",
                    sm: "center",
                    xs: "center",
                  }}
                >
                  {t("footer:news-letter.text")}
                </Typography>
              </Box>
              <NewsLetter />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: {
                lg: "row",
                md: "row",
                sm: "column",
                xs: "column",
              },
              alignItems: "flex-start",
              gap: { lg: 3, md: 3, sm: 5, xs: 5 },
            }}
          >
            <Links />
            <SocialMedia />
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Divider color="#9e9e9e" sx={{ my: 3 }} />
          <BottomNav />
        </Box>
      </Box>
    </Box>
  );
};
