import { SxProps, Theme } from "@mui/material/styles";

export const AppbarStyle = (theme: Theme) => ({
  zIndex: theme.zIndex.drawer,
  display: "flex",
  flexDirection: "columm",
  justifyContent: "center",
  alignItems: "center",
  boxShadow: "none",
});

export const AppbarContainerStyle: SxProps<Theme> = (theme: Theme) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: {
    md: theme.spacing(3),
    xs: theme.spacing(2),
  },
  paddingBottom: {
    md: theme.spacing(3),
    xs: theme.spacing(2),
  },
  gap: 3,
});

export const NavbarStyle = () => ({
  display: "flex",
  flexDirection: "row",
  alignItems: "flex-start",
  flexGrow: { lg: 0, md: 0, sm: 1, xs: 1 },
  width: { lg: "auto", md: "auto", sm: "100%", xs: "100%" },
});

export const NavbarCTAStyle = () => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "center",
  gap: 2,
});
