import { Box, Button } from "@mui/material";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const LanguageLink = ({ locale }: { locale: "en" | "tr" }) => {
  const router = useRouter();
  const { i18n } = useTranslation();

  return (
    <Link
      id={`locale-switch-${locale}`}
      locale={locale}
      href={router.asPath}
      hrefLang={locale}
      scroll={false}
    >
      <Button
        sx={{
          minWidth: "unset",
          fontWeight: 500,
          color: i18n.language === locale ? "#ffffff" : "#ffffff7f",
        }}
        variant="text"
      >
        {locale.toUpperCase()}
      </Button>
    </Link>
  );
};

export const LanguageLinks = () => {
  return (
    <Box display="flex" gap={1} alignItems="center">
      <LanguageLink locale="en" /> | <LanguageLink locale="tr" />
    </Box>
  );
};
