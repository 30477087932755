import { Circle, ChevronRight } from "@beije/react-icons";
import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

type TitleSectionProps = {
  title: string;
  hideArrow?: boolean;
  onClick?: (title: string) => void;
  Icon?: React.MemoExoticComponent<
    (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  >;
  hideIcon?: boolean;
};

export const TitleSection = ({
  title,
  hideArrow,
  onClick,
  Icon,
  hideIcon,
}: TitleSectionProps) => {
  const theme = useTheme();
  return (
    <Box
      title={title}
      onClick={() => onClick && onClick(title)}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1.5,
        padding: {
          xs: theme.spacing(1.5, 0),
          md: 0,
        },
      }}
    >
      {!hideIcon &&
        (Icon ? (
          <Icon width={24} height={24} />
        ) : (
          <Circle width={24} height={24} />
        ))}
      <Typography
        variant="desktopBody1"
        sx={{
          display: "flex",
          flexGrow: 1,
          flex: "1 1 auto",
          width: "100%",
          alignItems: "center",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
            transition: "all 0.5s ease",
          },
        }}
      >
        {title}
      </Typography>
      {!hideArrow && <ChevronRight width={24} height={24} />}
    </Box>
  );
};
