import { Box, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { type ImageProps } from "next/image";
import Link from "next/link";
import React, { CSSProperties } from "react";

import {
  BackendImage,
  type BackendImageProps,
} from "src/components/Common/BackendImage";

import { TitleSection } from "./TitleSection";
import { SafeImage } from "../SafeImage";

type SubMenuItemProps = {
  item: {
    title: string;
    route?: string;
    isExternal?: boolean;
    frontendImage?: ImageProps["src"];
    backendImage?: BackendImageProps["src"];
  };
  action?: () => void;
  hideIcon?: boolean;
  Icon?: React.MemoExoticComponent<
    (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  >;
};

export const SubMenuItem = ({
  item,
  action,
  hideIcon,
  Icon,
}: SubMenuItemProps) => {
  const theme = useTheme();
  const mdBreakpoint = useMediaQuery(theme.breakpoints.up("md"));

  const imageStyles: CSSProperties = {
    objectFit: "cover",
    objectPosition: "center",
    aspectRatio: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "#f7f6f5",
  };

  const ChildComponent = (
    <Box
      onClick={action}
      title={item.title}
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        width: "100%",
        cursor: "pointer",
      }}
    >
      <Box
        sx={{
          display: {
            xs: "none",
            md: "block",
          },
          position: "relative",
          width: "100%",
          height: "100%",
          maxWidth: 270,
          maxHeight: 135,
          aspectRatio: "18/9",
          backgroundColor: theme.palette.background.white,
        }}
      >
        {!!item.frontendImage && (
          <SafeImage
            src={item.frontendImage}
            alt={item.title}
            fill
            sizes="100vw"
            style={imageStyles}
          />
        )}
        {!!item.backendImage && (
          <BackendImage
            src={item.backendImage}
            fill
            sizes="100vw"
            style={imageStyles}
          />
        )}
      </Box>
      <TitleSection
        title={item.title}
        hideArrow={!mdBreakpoint}
        Icon={Icon}
        hideIcon={hideIcon}
      />
    </Box>
  );

  return item.route ? (
    <Link href={item.route} onClick={action}>
      {ChildComponent}
    </Link>
  ) : (
    ChildComponent
  );
};
