import { SVGProps, memo } from "react";

const SvgTampon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4.12967 9.3125H14.1574C14.3716 9.3125 14.5452 9.48609 14.5452 9.70031V12.559C14.5452 12.7733 14.3716 12.9468 14.1574 12.9468H4.12967C3.12506 12.9468 2.3125 12.1343 2.3125 11.1297C2.3125 10.1251 3.12506 9.3125 4.12967 9.3125Z"
      stroke="#343131"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M14.5469 11.1328H15.9282C16.9882 11.1328 17.8451 11.9934 17.8451 13.0497C17.8451 14.106 18.7057 14.9666 19.762 14.9666C20.8183 14.9666 21.6789 14.106 21.6789 13.0497"
      stroke="#343131"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
);

const Memo = memo(SvgTampon);
export default Memo;
