import { SVGProps, memo } from "react";

const SvgCup = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.661 21.9957C11.9672 21.9957 12.3373 21.7916 12.3373 21.3194C12.3373 20.8473 12.3373 15.7941 12.3373 15.7941C12.3373 15.7941 16.225 13.4249 17.1225 10.8898C17.8881 8.72905 17.5181 6.0621 17.4798 5.41131C17.4458 4.82008 17.3735 3.95661 17.6797 3.67588C18.0498 3.58656 18.3262 3.25053 18.3262 2.8507C18.3262 2.38282 17.9477 2 17.4798 2H11.6652H5.8507C5.37856 2 5 2.37856 5 2.8507C5 3.25053 5.27648 3.58231 5.64653 3.67588C5.95279 3.95661 5.88048 4.81582 5.84645 5.41131C5.80817 6.0621 5.43811 8.72905 6.20374 10.8941C7.10123 13.4334 10.9889 15.7984 10.9889 15.7984V21.3237C10.9889 21.7958 11.359 22 11.6652 22L11.661 21.9957Z"
      stroke="#343131"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

const Memo = memo(SvgCup);
export default Memo;
