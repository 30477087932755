import Image, { type ImageProps } from "next/image";
import PLACEHOLDER from "public/images/placeholder.png";
import { useEffect, useState } from "react";

type SafeImageProps = Omit<
  ImageProps,
  "onError" | "blurDataURL" | "placeholder"
>;
export const SafeImage = (props: SafeImageProps) => {
  const [src, setSrc] = useState<SafeImageProps["src"]>(PLACEHOLDER);

  useEffect(() => {
    setSrc(props.src);
  }, [props]);

  return (
    <Image
      {...props}
      src={src}
      alt={props.alt}
      placeholder="blur"
      blurDataURL={PLACEHOLDER.src}
      onError={() => {
        console.error(["Error loading image, replacing with placeholder", src]);
        setSrc(PLACEHOLDER);
      }}
    />
  );
};
