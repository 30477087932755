export const TextFieldStyle = () => ({
  maxWidth: {
    xs: "100%",
    sm: "100%",
  },
  border: "1px solid rgba(255,255,255,0.23)",
  color: "#FFFFFFB2",
  "& label": {
    color: "#FFFFFFB2",
    background: "#262626",
  },
  "& input": {
    color: "#FFFFFFB2",
  },
  "& label.Mui-focused": {
    color: "#FFFFFFB2",
    background: "#262626",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#FFFFFFB2",
      border: "none",
    },
    "&:hover fieldset": {
      color: "#FFFFFFB2",
      border: "none",
    },
    "&.Mui-focused fieldset": {
      color: "#FFFFFFB2",
      border: "none",
    },
  },
});
