import { Menu01, XClose } from "@beije/react-icons";
import { IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";

import type { MenuType } from "./constants";

type MobileNavigationOpenCloseButtonProps = {
  setSelectedMenu: (type: MenuType) => void;
  selectedMenu: MenuType;
  color?: string;
};

export const MobileNavigationOpenCloseButton = ({
  setSelectedMenu,
  selectedMenu,
  color,
}: MobileNavigationOpenCloseButtonProps) => {
  const { t } = useTranslation();

  const iconProps = {
    width: 24,
    height: 24,
    color,
  };

  let IconElement = Menu01;
  let aria = "open-menu";
  let clickAction: MenuType = "mobile";

  if (selectedMenu === "mobile") {
    IconElement = XClose;
    aria = "close-menu";
    clickAction = "closed";
  }

  return (
    <IconButton
      sx={{ padding: 0.5 }}
      aria-label={t(`nav:aria.${aria}`)}
      onClick={() => setSelectedMenu(clickAction)}
    >
      <IconElement {...iconProps} />
    </IconButton>
  );
};
