import { type ImageProps } from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";
import React from "react";

import { type BackendImageProps } from "src/components/Common/BackendImage";

import { TitleSection } from "./TitleSection";

type ParentMenuItemProps = {
  item: {
    titleKey: string;
    route?: string;
    isExternal?: boolean;
    frontendImage?: ImageProps["src"];
    backendImage?: BackendImageProps["src"];
  };
  onClick?: (titleKey: string) => void;
  hideArrow?: boolean;
  Icon?: React.MemoExoticComponent<
    (props: React.SVGProps<SVGSVGElement>) => JSX.Element
  >;
  hideIcon?: boolean;
};

export const MobileMainMenuItem = ({
  item,
  onClick,
  hideArrow,
  hideIcon,
}: ParentMenuItemProps) => {
  const { t } = useTranslation();

  const ParentComponent = (
    <TitleSection
      onClick={() => onClick && onClick(item.titleKey)}
      title={t(`nav:${item.titleKey}`)}
      hideArrow={hideArrow}
      hideIcon={hideIcon}
    />
  );

  return item.route ? (
    <Link href={item.route} target={item.isExternal ? "_blank" : "_self"}>
      {ParentComponent}
    </Link>
  ) : (
    ParentComponent
  );
};
