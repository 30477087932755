import {
  User01,
  ChevronDown,
  Calendar,
  Package,
  LogOut01,
} from "@beije/react-icons";
import {
  Box,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  Divider,
  Icon,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";

import { RootState } from "src/store";

type AccountMenuProps = {
  color?: string;
};
export const NavigationAccountMenu = ({ color }: AccountMenuProps) => {
  const router = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const authState = useSelector((state: RootState) => state.AuthSlice);
  const isLoggedIn = !!authState.account;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!isDesktop || !isLoggedIn) {
    return (
      <Link
        href={isLoggedIn ? "/account" : "/auth/login"}
        aria-label={t(`nav:aria.${isLoggedIn ? "account" : "login"}}`)}
      >
        <Icon
          sx={{
            padding: 0.5,
            width: "auto",
            height: "auto",
            display: "inline-flex",
            position: "relative",
          }}
        >
          <User01 width={24} height={24} color={color} />
        </Icon>
      </Link>
    );
  }

  const userFirstName = authState.account?.firstName;

  let logoutRedirectPath = router.asPath;
  if (router.asPath.startsWith("/account")) {
    logoutRedirectPath = "/";
  }
  if (router.asPath.startsWith("/checkout")) {
    logoutRedirectPath = "/cart";
  }
  const logoutHref = `/auth/logout?redirect=${logoutRedirectPath}`;

  return (
    <>
      <Box>
        <Box
          component="div"
          onClick={handleClick}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            gap: "4px",
            cursor: "pointer",
          }}
          aria-controls={open ? "account-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <User01 width={24} height={24} color={color} />
          <Typography
            component="span"
            variant="desktopSubtitle1"
            sx={{ textTransform: "capitalize", pt: "4px" }}
            color={color}
          >
            {userFirstName}
          </Typography>
          <ChevronDown
            style={{ alignSelf: "flex-end" }}
            width={24}
            height={24}
            color={color}
          />
        </Box>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            boxShadow:
              "0px 1px 3px rgba(0, 0, 0, 0.03), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05), 0px 5px 15px rgba(0, 0, 0, 0.05)",
            mt: 1.5,
            background: theme.palette.background.paper,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Link href="/account">
          <MenuItem sx={{ py: 1 }}>
            <ListItemIcon>
              <Calendar />
            </ListItemIcon>
            {t("nav:my-subscription")}
          </MenuItem>
        </Link>
        <Link href="/account/orders">
          <MenuItem sx={{ py: 1 }}>
            <ListItemIcon>
              <Package />
            </ListItemIcon>
            {t("nav:my-orders")}
          </MenuItem>
        </Link>
        <Link href="/account/profile">
          <MenuItem sx={{ py: 1 }}>
            <ListItemIcon>
              <User01 />
            </ListItemIcon>
            {t("nav:my-user-info")}
          </MenuItem>
        </Link>
        <Divider />
        <Link href={logoutHref}>
          <MenuItem sx={{ py: 1 }}>
            <ListItemIcon>
              <LogOut01 />
            </ListItemIcon>
            {t("nav:log-out")}
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};
