import { Box, Typography, useMediaQuery } from "@mui/material";
import { Theme, useTheme } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";

import FacebookIcon from "public/icons/Facebook.svg";
import InstagramIcon from "public/icons/Instagram.svg";
import LinkedinIcon from "public/icons/LinkedIn.svg";
import SpotifyIcon from "public/icons/Spotify.svg";
import TwitterIcon from "public/icons/Twitter.svg";
import { FC } from "react";

const SocialMedia: FC = () => {
  const socialMediaLinks = [
    {
      icon: FacebookIcon,
      url: "https://www.facebook.com/beijewomen/",
      text: "Facebook",
    },
    {
      icon: InstagramIcon,
      url: "https://www.instagram.com/beijewomen/",
      text: "Instagram",
    },
    {
      icon: TwitterIcon,
      url: "https://twitter.com/beijewomen",
      text: "Twitter",
    },
    {
      icon: LinkedinIcon,
      url: "https://tr.linkedin.com/company/beije-tr",
      text: "Linkedin",
    },
    {
      icon: SpotifyIcon,
      url: "https://open.spotify.com/user/ma20bga4ewhwt96kx1p92f2uv",
      text: "Spotify",
    },
  ];

  const myTheme = useTheme();

  const mediumBreakpoint = useMediaQuery(myTheme.breakpoints.down("md"));

  const containerStyle = (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: 3,
    ml: 5,
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      ml: 0,
    },
  });

  const socialMediaStyle = (theme: Theme) => ({
    display: "flex",
    alignItems: "center",
    gap: 1,
  });

  return (
    <Box sx={containerStyle}>
      {socialMediaLinks.map((link, index) => (
        <Link key={index} href={link.url} target="_blank">
          <Box sx={socialMediaStyle}>
            <Image
              src={link.icon}
              alt={link.text}
              style={{
                maxWidth: "100%",
                height: "auto",
              }}
            />
            {!mediumBreakpoint && <Typography>{link.text}</Typography>}
          </Box>
        </Link>
      ))}
    </Box>
  );
};

export default SocialMedia;
