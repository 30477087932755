import { type ImageProps } from "next/image";

import { BACKEND_API_BASE_URL, STATIC_CONTENTS_BASE_URL } from "src/constant";

import { SafeImage } from "./SafeImage";

export type BackendImageProps = Omit<ImageProps, "src" | "alt"> & {
  src: {
    entity: "packet" | "packetType" | "product" | "productType";
    idType: "_id" | "slug";
    id: string;
    variant: "header" | "slider" | "card" | "preview" | "preview-mobile";
  };
};

export const packetSlugToPicture = (packetSlug: string) => {
  const subTypes = packetSlug.split("_");
  const subTypesWithoutQuantity = subTypes.map((s) => {
    const [typeSlug, subTypeSlug, _] = s.split(".");
    return [typeSlug, subTypeSlug].join(".");
  });
  const slugWithoutQuantity = subTypesWithoutQuantity.join("_");

  return `${STATIC_CONTENTS_BASE_URL}/packets_service/images/${slugWithoutQuantity}.png`;
};

export const BackendImage = ({ src, ...props }: BackendImageProps) => {
  let imageSrc = `${BACKEND_API_BASE_URL}/catalog_service/v1/image/${src.entity}/${src.idType}/${src.id}?variant=${src.variant}`;
  const imageAlt = `${src.entity} ${src.id} ${src.variant}`;
  if (src.entity === "packet" && src.idType === "slug") {
    imageSrc = packetSlugToPicture(src.id);
  }

  return <SafeImage src={imageSrc} alt={imageAlt} {...props} />;
};
