import { Box, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import Image from "next/image";
import Link from "next/link";
import { useTranslation } from "next-i18next";

import MasterIcon from "public/icons/Master.svg";
import VisaIcon from "public/icons/Visa.svg";
import { useState } from "react";

import { LanguageLinks } from "./LanguageLinks";

export const BottomNav = () => {
  const { t } = useTranslation();

  const [links, _setLinks] = useState([
    {
      text: "kvkk",
      route: "/know-us/kvkk",
    },
    {
      text: "membership-agreement",
      route: "/know-us/membership-agreement",
    },
    {
      text: "privacy",
      route: "/know-us/privacy",
    },
    {
      text: "cookies",
      route: "/know-us/cookies",
    },
    {
      text: "test-results",
      route: "/know-us/test-results",
    },
  ]);

  const containerStyle = (theme: Theme) => ({
    display: "flex",
    flexDirection: "column",
    gap: 3,
    alignItems: "center",
    justifyContent: "space-between",
    mt: "2rem",
    mb: "4rem",
    mx: "auto",
    color: "#ffffff7f",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
  });

  const linksStyle = (theme: Theme) => ({
    display: "flex",
    gap: 3,
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      textAlign: "center",
      mb: 3,
    },
  });

  return (
    <Box>
      <Box sx={containerStyle}>
        <Typography component="p" variant="subtitle2">
          {t("footer:copyright", { year: new Date().getFullYear() })}
        </Typography>

        <Box sx={linksStyle}>
          {links.map((link) => (
            <Box key={link.text}>
              <Link href={link.route}>
                <Typography variant="body2">
                  {t(`footer:${link.text}`)}
                </Typography>
              </Link>
            </Box>
          ))}
        </Box>

        <LanguageLinks />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" gap={1}>
        <Image
          alt="MasterCard"
          src={MasterIcon}
          width={50}
          height={50}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
        <Image
          alt="VISA"
          src={VisaIcon}
          width={50}
          height={50}
          style={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Box>
    </Box>
  );
};
