import {
  ChevronLeft,
  NavPad,
  NavPantyliner,
  NavTampon,
  NavCup,
  ArrowRight,
  ShoppingBag02,
  Wallet05,
  Settings03,
} from "@beije/react-icons";
import {
  Box,
  Container,
  useMediaQuery,
  Typography,
  Menu,
  Slide,
  SlideProps,
  Button,
  Grid,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import Router from "next/router";
import { useTranslation } from "next-i18next";
import React, { useEffect, useMemo } from "react";

import {
  useGetPacketTypesQuery,
  useGetProductTypesQuery,
} from "src/store/apis";

import { MENU_DATA, type MenuType } from "./constants";

import { MobileMainMenuItem } from "./MobileMainMenuItem";
import styles from "./Navigation.module.scss";
import { SubMenuItem } from "./SubMenuItem";
import { TitleSection } from "./TitleSection";
import { SafeImage } from "../SafeImage";

const PRODUCT_TYPE_ICONS = [NavPad, NavPantyliner, NavTampon, NavCup];
const PACKET_TYPE_ICONS = [
  Wallet05,
  NavPad,
  NavPantyliner,
  NavTampon,
  ShoppingBag02,
];

const parentMenus = ["products", "donation-culture", "know-us"];

const Line = () => <hr style={{ backgroundColor: "#343131", opacity: 0.3 }} />;

type HeaderMenuProps = {
  setSelectedMenu: React.Dispatch<React.SetStateAction<MenuType>>;
  headerRef: React.RefObject<HTMLDivElement>;
  selectedMenu: MenuType;
};

export const HeaderMenu = ({
  headerRef,
  selectedMenu,
  setSelectedMenu,
}: HeaderMenuProps) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));

  const { data: productTypes } = useGetProductTypesQuery();
  const { data: packetTypes } = useGetPacketTypesQuery();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const isMenuOpen = Boolean(menuAnchorEl);

  const closeMenu = () => {
    setSelectedMenu("closed");
  };

  useEffect(() => {
    if (selectedMenu === "closed") {
      setMenuAnchorEl(null);
    } else {
      setMenuAnchorEl(headerRef.current);
    }
  }, [selectedMenu, headerRef]);

  useEffect(() => {
    Router.events.on("routeChangeStart", () => {
      closeMenu();
    });

    return () => {
      Router.events.off("routeChangeStart", () => {
        closeMenu();
      });
    };
  }, []);

  const PRODUCTS_TYPE_FOR_SHOW: string[] = [
    "tampons",
    "pads",
    "cup",
    "pantyliner",
  ];
  const FILTERED_PRODUCT_TYPES = useMemo(
    () =>
      productTypes?.filter((item) =>
        PRODUCTS_TYPE_FOR_SHOW.includes(item.pageIdentifier)
      ),
    [productTypes]
  );
  const SORTED_PRODUCT_TYPES = useMemo(
    () => FILTERED_PRODUCT_TYPES?.sort((a, b) => a.sortOrder - b.sortOrder),
    [FILTERED_PRODUCT_TYPES]
  );

  const PACKETS_TYPE_FOR_SHOW: string[] = ["tampons", "pads", "pantyliner"];
  const FILTERED_PACKET_TYPES = useMemo(
    () =>
      packetTypes?.filter((item) => PACKETS_TYPE_FOR_SHOW.includes(item.slug)),
    [packetTypes]
  );
  const SORTED_PACKET_TYPES = useMemo(
    () => FILTERED_PACKET_TYPES?.sort((a, b) => a.sortOrder - b.sortOrder),
    [FILTERED_PACKET_TYPES]
  );

  const headerProductContentLeftSideHeight =
    headerRef?.current?.clientHeight || 0;

  const handleSubmenuTitleClick = () => {
    if (!desktop) {
      // On mobile, when user clicks title, we go back to the main menu
      setSelectedMenu("mobile");
    }
  };

  const handleMobileMenuItemClick = (title: string) => {
    if (parentMenus.includes(title)) {
      setSelectedMenu(title as MenuType);
    }
  };

  return (
    <Menu
      anchorEl={menuAnchorEl}
      open={isMenuOpen}
      onClose={closeMenu}
      sx={{ left: 0 }}
      PopoverClasses={{
        root: desktop ? styles["menu-root"] : styles["menu-root--mobile"],
        paper: styles["menu-paper"],
      }}
      PaperProps={{
        sx: {
          width: "100%",
          left: "0 !important",
          top: "60px",
          maxWidth: "none",
          borderRadius: 0,
          maxHeight: {
            xs: "calc(100vh - 64px)",
            md: "calc(90vh - 80px)",
          },
          "&::-webkit-scrollbar": {
            width: "5px",
            height: "10px",
            borderRadius: "3px",
            backgroundColor: "#fafafa",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#4d4d4d",
            borderRadius: "3px",
          },
        },
      }}
      TransitionComponent={Slide}
      TransitionProps={
        {
          direction: desktop ? "down" : "left",
          container: headerRef.current,
          in: isMenuOpen,
          mountOnEnter: true,
          unmountOnExit: true,
          timeout: 150,
        } as unknown as SlideProps
      }
      MenuListProps={{
        onMouseLeave: () => {
          if (desktop) {
            closeMenu();
          }
        },
        sx: {
          padding: 0,
        },
      }}
      hideBackdrop
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          padding: {
            md: theme.spacing(3, 0, 9),
            xs: theme.spacing(3),
          },
          gap: {
            md: 6,
          },
        }}
      >
        {selectedMenu === "mobile" &&
          Object.values(MENU_DATA).map((item) => (
            <MobileMainMenuItem
              key={item.titleKey}
              item={item}
              onClick={handleMobileMenuItemClick}
              hideArrow={!parentMenus.includes(item.titleKey)}
              hideIcon
            />
          ))}

        {selectedMenu === "products" && (
          <>
            <Grid container spacing={4}>
              <Grid item xs={12} md={10}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: 0,
                      md: 3,
                    },
                  }}
                >
                  <Box>
                    <Typography
                      component="h5"
                      variant="desktopH5"
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 1.5,
                        cursor: "pointer",
                      }}
                      onClick={handleSubmenuTitleClick}
                    >
                      {!desktop && <ChevronLeft />}
                      {t("nav:products")}
                    </Typography>
                  </Box>
                  {!desktop && (
                    <Box sx={{ px: 4, my: 2 }}>
                      <Line />
                    </Box>
                  )}

                  <Box
                    sx={{
                      px: !desktop ? 4 : 0,
                    }}
                  >
                    <Grid container spacing={!desktop ? 1 : 3}>
                      {SORTED_PRODUCT_TYPES?.map((item, index) => (
                        <Grid
                          key={item._id}
                          item
                          lg={12 / 4}
                          md={12 / 4}
                          xs={12}
                        >
                          <SubMenuItem
                            item={{
                              title: item.name[i18n.language as "tr" | "en"],
                              route: `/product/${item.pageIdentifier}`,
                              backendImage: {
                                entity: "productType",
                                idType: "slug",
                                id: item.pageIdentifier,
                                variant: "header",
                              },
                            }}
                            Icon={PRODUCT_TYPE_ICONS[index]}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                  {!desktop && (
                    <Box sx={{ px: 4, mt: 2 }}>
                      <Line />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {
                      xs: 0,
                      md: 3,
                    },
                    px: !desktop ? 4 : 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      mt: 4,
                    }}
                  >
                    <Typography
                      component="h5"
                      variant="desktopH5"
                      sx={{ my: !desktop ? 2 : 0 }}
                    >
                      {t("nav:packets")}
                    </Typography>
                    <Link href="/packets">
                      <Button variant="text" endIcon={<ArrowRight />}>
                        {t("nav:all-packets")}
                      </Button>
                    </Link>
                  </Box>
                  <Box>
                    <Grid container spacing={!desktop ? 1 : 3}>
                      <Grid item lg={12 / 5} md={12 / 5} xs={12}>
                        <SubMenuItem
                          key={0}
                          item={{
                            title: t("nav:popular-packets"),
                            route: "/packets/popular",
                            frontendImage: "/images/nav/popular-packet.png",
                          }}
                          Icon={PACKET_TYPE_ICONS[0]}
                        />
                      </Grid>
                      {SORTED_PACKET_TYPES?.map((item, index) => (
                        <Grid
                          key={item._id}
                          item
                          lg={12 / 5}
                          md={12 / 5}
                          xs={12}
                        >
                          <SubMenuItem
                            item={{
                              title: item.name[i18n.language],
                              route: `/packets/${item.slug}`,
                              backendImage: {
                                entity: "packetType",
                                idType: "slug",
                                id: item.slug,
                                variant: "header",
                              },
                            }}
                            action={closeMenu}
                            Icon={PACKET_TYPE_ICONS[index + 1]}
                          />
                        </Grid>
                      ))}
                      <Grid item lg={12 / 5} md={12 / 5} xs={12}>
                        <SubMenuItem
                          key={5}
                          item={{
                            title: t("nav:trial-pack"),
                            route: "/product/trial",
                            frontendImage: "/images/nav/trial-pack.png",
                          }}
                          Icon={PACKET_TYPE_ICONS[4]}
                        />
                      </Grid>
                    </Grid>
                    {!desktop && (
                      <Box sx={{ my: 1 }}>
                        <Line />
                      </Box>
                    )}
                  </Box>
                  {!desktop && (
                    <Link href="/custom-packet">
                      <Button variant="text" startIcon={<Settings03 />}>
                        {t("nav:create-your-own-set")}
                      </Button>
                    </Link>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} md={2}>
                {desktop && (
                  <Link href="/custom-packet">
                    <Box
                      mt={6.5}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-end"
                      height={`${headerProductContentLeftSideHeight * 3}px`}
                      gap={1}
                    >
                      <Box position="relative" height="90%" width="100%">
                        <SafeImage
                          src="/images/nav/custom-packet.png"
                          alt={t("nav:create-your-own-set")}
                          fill
                          sizes="100vw"
                          style={{
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </Box>
                      <TitleSection
                        title={t("nav:create-your-own-set")}
                        hideArrow={!desktop}
                        Icon={Settings03}
                      />
                    </Box>
                  </Link>
                )}
              </Grid>
            </Grid>
          </>
        )}

        {(selectedMenu === "know-us" ||
          selectedMenu === "donation-culture") && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: {
                xs: 0,
                md: 3,
              },
            }}
          >
            <Box>
              <Typography
                component="h5"
                variant="desktopH5"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  cursor: "pointer",
                  mb: !desktop ? 2 : 0,
                }}
                onClick={handleSubmenuTitleClick}
              >
                {!desktop && <ChevronLeft />}
                {t(`nav:${selectedMenu}`)}
              </Typography>
            </Box>
            {!desktop && (
              <Box sx={{ px: 4 }}>
                <Line />
              </Box>
            )}
            <Box sx={{ px: !desktop ? 4 : 0 }}>
              <Grid container spacing={!desktop ? 1 : 3}>
                {MENU_DATA[selectedMenu].children?.map((item) => (
                  <Grid
                    key={item.titleKey}
                    item
                    lg={selectedMenu === "know-us" ? 12 / 5 : 12 / 4}
                    md={selectedMenu === "know-us" ? 12 / 5 : 12 / 4}
                    xs={12}
                  >
                    <SubMenuItem
                      item={{
                        ...item,
                        title: t(`nav:${item.titleKey}`),
                      }}
                      action={closeMenu}
                      hideIcon
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Box>
        )}
      </Container>
    </Menu>
  );
};
