import { Typography, Box, TextField, Button } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { useDispatch } from "react-redux";

import { useSubscribeNewsletterMutation } from "src/store/apis";
import { showSnackbar } from "src/store/slices";

import { TextFieldStyle } from "./styles";

type NewsletterFormData = {
  email: string;
};
const NewsLetter: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [subscribeAPI] = useSubscribeNewsletterMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<NewsletterFormData>();

  // Remove space from start and end of email value
  const emailValue = watch("email");
  useEffect(() => {
    setValue("email", emailValue?.trim()?.toLocaleLowerCase("en-US"));
  }, [emailValue]);

  const handleSubscription: SubmitHandler<NewsletterFormData> = (data) => {
    subscribeAPI(data).then((res) => {
      dispatch(
        showSnackbar({ msg: t("home:subscribed-successfully"), action: true })
      );
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: 2,
      }}
    >
      <Box
        component="form"
        sx={{
          display: "flex",
          flexDirection: { lg: "row", md: "row", sm: "column", xs: "column" },
          justifyContent: {
            lg: "unset",
            md: "unset",
            sm: "center",
            xs: "center",
          },
          gap: 2,
          alignItems: {
            lg: "center",
            md: "center",
            sm: "flex-start",
            xs: "flex-start",
          },
          width: "100%",
        }}
        onSubmit={handleSubmit(handleSubscription)}
      >
        <TextField
          {...register("email", {
            required: t("auth:signup.email.required"),
          })}
          name="email"
          size="small"
          sx={TextFieldStyle}
          variant="outlined"
          inputMode="email"
          label={t("footer:news-letter.input-text")}
          placeholder={t("footer:news-letter.placeholder")}
          type="email"
          error={!!errors.email}
          fullWidth
        />
        <Button
          type="submit"
          variant="contained"
          size="large"
          sx={{
            backgroundColor: "#FFFFFF",
            color: "#000000",
            opacity: 0.87,
            width: { lg: "auto", md: "auto", sm: "100%", xs: "100%" },
          }}
        >
          {t("footer:submit")}
        </Button>
      </Box>
      <Typography
        component="p"
        sx={{
          fontSize: ".75rem",
          fontWeight: 400,
          lineHeight: "20px",
          opacity: 0.7,
          textAlign: { lg: "unset", md: "unset", sm: "center", xs: "center" },
        }}
      >
        {t("footer:news-letter.privacy")}
      </Typography>
    </Box>
  );
};
export default NewsLetter;
