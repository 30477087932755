import {
  AppBar,
  Box,
  Container,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Link from "next/link";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { useState, useEffect, useRef } from "react";

import { MobileNavigationOpenCloseButton } from "src/components/Common/Navigation/MobileNavigationOpenCloseButton";

import { CartBadgeButton } from "./CartBadgeButton";
import { MENU_DATA } from "./constants";

import { HeaderMenu } from "./HeaderMenu";
import { NavbarLogo } from "./NavbarLogo";
import { NavigationAccountMenu } from "./NavigationAccountMenu";
import { AppbarStyle } from "./styles";
import { TopHeader } from "./TopHeader";

const NOT_STICKY_PATHS = ["/product/[pageIdentifier]", "/packet/[slug]"];

type MenuType =
  | "products"
  | "know-us"
  | "donation-culture"
  | "mobile"
  | "closed";

type NavigationHeaderProps = {
  invert?: boolean;
};

export const NavigationHeader = ({ invert = false }: NavigationHeaderProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const desktop = useMediaQuery(theme.breakpoints.up("md"));
  const router = useRouter();

  const headerRef = useRef<null | HTMLDivElement>(null);
  const [selectedMenu, setSelectedMenu] = useState<MenuType>("closed");
  const isMenuOpen = selectedMenu !== "closed";

  const [sticky, setSticky] = useState(invert);
  const isSticky = sticky || invert;
  const color = !sticky && !invert ? theme.palette.common.white : undefined;

  useEffect(() => {
    const handleScroll = () => {
      if (!invert) {
        setSticky(window.scrollY >= 80);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [sticky]);

  useEffect(() => {
    setSticky(isMenuOpen);
  }, [isMenuOpen]);

  const handleNavClick = (type: string) => {
    switch (type) {
      case "products":
      case "know-us":
      case "donation-culture":
      case "mobile":
        setSelectedMenu(type);
        break;
      default:
        break;
    }
  };

  const ItemsStyle = {
    color: isSticky ? "text.primary" : "common.white",
    cursor: "pointer",
    borderBottom: "1px solid transparent",
    whiteSpace: "nowrap",
    "&:hover": {
      borderColor: isSticky ? "text.primary" : "common.white",
      transition: "all 0.5s ease",
    },
    "&:click": {
      borderColor: isSticky ? "text.primary" : "common.white",
      borderBottom: "1px solid transparent",
      transition: "all 0.5s ease",
      fontWeight: "bold",
    },
  };

  const shouldNotBeSticky = NOT_STICKY_PATHS.includes(router.pathname);

  const containerStyle = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: {
      md: theme.spacing(3),
      xs: theme.spacing(2),
    },
    paddingBottom: {
      md: theme.spacing(3),
      xs: theme.spacing(2),
    },
    gap: 3,
  };

  return (
    <>
      <AppBar
        sx={[
          AppbarStyle,
          {
            position: shouldNotBeSticky ? "static" : undefined,
          },
        ]}
        component="header"
        color={isSticky ? "default" : "transparent"}
        ref={headerRef}
      >
        <TopHeader />

        <Container sx={containerStyle}>
          {/** Left Side Logo  */}
          <NavbarLogo isSticky={isSticky} />

          {/** Desktop -> Center Links */}
          {desktop && (
            <Box
              component="nav"
              sx={{
                display: "flex",
                gap: 4,
                flexGrow: 1,
                zIndex: 1400,
                flexWrap: "nowrap",
              }}
            >
              {Object.values(MENU_DATA).map((item) =>
                item.route ? (
                  <Link
                    href={item.route}
                    target={item.isExternal ? "_blank" : "_self"}
                    key={item.titleKey}
                  >
                    <Typography
                      variant="desktopBody1"
                      tabIndex={0}
                      sx={ItemsStyle}
                    >
                      {t(`nav:${item.titleKey}`)}
                    </Typography>
                  </Link>
                ) : (
                  <Typography
                    onMouseOver={() => handleNavClick(item.titleKey)}
                    key={item.titleKey}
                    variant="desktopBody1"
                    tabIndex={0}
                    sx={ItemsStyle}
                    onClick={() => handleNavClick(item.titleKey)}
                  >
                    {t(`nav:${item.titleKey}`)}
                  </Typography>
                )
              )}
            </Box>
          )}

          {/** Right Side Buttons */}
          <Box
            component="nav"
            sx={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <CartBadgeButton color={color} />

            <NavigationAccountMenu color={color} />

            {/** Mobile -> Menu open/close icon */}
            {!desktop && (
              <MobileNavigationOpenCloseButton
                setSelectedMenu={setSelectedMenu}
                selectedMenu={selectedMenu}
                color={color}
              />
            )}
          </Box>
        </Container>

        <HeaderMenu
          headerRef={headerRef}
          selectedMenu={selectedMenu}
          setSelectedMenu={setSelectedMenu}
        />
      </AppBar>
    </>
  );
};
