import { BeijeLogo02, BeijeLogo17 } from "@beije/react-icons";
import { Box } from "@mui/system";
import Link from "next/link";
import { useTranslation } from "react-i18next";

type NavbarLogoProps = {
  isSticky: boolean;
};

export const NavbarLogo = ({ isSticky }: NavbarLogoProps) => {
  const { t } = useTranslation();

  const IconElement = isSticky ? BeijeLogo02 : BeijeLogo17;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: 172,
      }}
    >
      <Link href="/" tabIndex={0} aria-label={t("common:aria.home")}>
        <IconElement
          width={56.39}
          height={24}
          style={{
            cursor: "pointer",
          }}
        />
      </Link>
    </Box>
  );
};
