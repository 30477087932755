import { SVGProps, memo } from "react";

const SvgPantyliner = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={props.width || 24}
    height={props.height || 24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.39062 12.2907C2.39062 15.7743 4.13476 16.506 5.92951 16.0827C7.72427 15.6593 10.3106 15.4844 11.9995 15.4844C13.6884 15.4844 16.2793 15.6593 18.0694 16.0827C19.8642 16.506 21.6083 15.7743 21.6083 12.2907C21.6083 8.80701 19.8642 8.07531 18.0694 8.49868C16.2747 8.92206 13.6884 9.09693 11.9995 9.09693C10.3106 9.09693 7.71966 8.92206 5.92951 8.49868C4.13936 8.07531 2.39062 8.80701 2.39062 12.2907Z"
      stroke="#343131"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
    <path
      d="M15.8752 10.5586H8.12095C7.16532 10.5586 6.39062 11.3333 6.39062 12.2889C6.39062 13.2446 7.16532 14.0192 8.12095 14.0192H15.8752C16.8308 14.0192 17.6055 13.2446 17.6055 12.2889C17.6055 11.3333 16.8308 10.5586 15.8752 10.5586Z"
      stroke="#343131"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
);

const Memo = memo(SvgPantyliner);
export default Memo;
